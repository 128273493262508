body {
  background-color: #eff2f5;
}

:root {
  --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Amatic SC", sans-serif;
  --font-secondary: "Inter", sans-serif;
}
.test{
  position: relative;

  /* margin-top: -38px; */
  /* padding-right: 10px; */
}
.testIcona{
  position: absolute;
  z-index:99;
  margin-top: 22px;
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #0C0B09;
  border: 1px solid #0C0B09;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cacaca;
}

.pagination a {
  margin: 0 9px;
  padding: 5px 13px;
  border-radius: 8px;
  color: black;
  background-color: #e1e5ee;
  text-decoration: none;
  border: 1px solid #6ECDE9;
}

.pagination a:hover {
  background-color: #6ECDE9;
  color: white;
}

.pagination .active  {
  background-color:#6ECDE9 ;
  color: #fff;
  border-color: #6ECDE9;
}
.pagination .active a {
  background-color:#6ECDE9 ;
  color: #fff;
  border-color: #6ECDE9;
}

@media only screen and (max-width: 600px) {
.notCollapsed{
   display: none;
  }
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  color: #626262;
  text-decoration: underline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.leaflet-control-attribution{
  display: none;
}