/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap"); */
/* :root {
    --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-primary: "Amatic SC", sans-serif;
    --font-secondary: "Inter", sans-serif;
  } */
  
  /* Colors */
  :root {
    --color-default: #212529;
    --color-primary:#6ECDE9;
    --color-secondary: #37373f;
    --brand-color:#6ECDE9;
    scroll-behavior: smooth;
  }
 
  
  /*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
  body {
    font-family: var(--font-default);
    color: var(--color-default);
  }
  
  a {
    color: var(--color-primary);
    text-decoration: none;
  }
  
  a:hover {
    color: #2996b6;;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-secondary);
  }
  
  /*--------------------------------------------------------------
  # Sections & Section Header
  --------------------------------------------------------------*/
  section {
    overflow: hidden;
    padding: 80px 0;
  }
  
  .section-bg {
    background-color: #eee;
  }
  
  .section-header {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #7f7f90;
    text-transform: uppercase;
    font-family: var(--font-default);
  }
  
  .section-header p {
    margin: 0;
    font-size: 48px;
    font-weight: 400;
    font-family: var(--font-primary);
  }
  
  .section-header p span {
    color: var(--color-primary);
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: rgba(55, 55, 63, 0.05);
    margin-top: 90px;
  }
  
  @media (max-width: 575px) {
    .breadcrumbs {
      margin-top: 70px;
    }
  }
  
  .breadcrumbs h2 {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #676775;
    content: "/";
  }
  
  @media (max-width: 992px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
  
    .breadcrumbs h2 {
      margin-bottom: 10px;
    }
  
    .breadcrumbs ol {
      display: block;
    }
  
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  /*--------------------------------------------------------------
  # Scroll top button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: var(--color-primary);
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .scroll-top:hover {
    background: #2996b6;
    color: #fff;
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    inset: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
    transition: all 0.6s ease-out;
    width: 100%;
    height: 100vh;
  }
  
  #preloader:before,
  #preloader:after {
    content: "";
    position: absolute;
    border: 4px solid var(--color-primary);
    border-radius: 50%;
    animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  #preloader:after {
    animation-delay: -0.5s;
  }
  
  @keyframes animate-preloader {
    0% {
      width: 10px;
      height: 10px;
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      opacity: 1;
    }
  
    100% {
      width: 72px;
      height: 72px;
      top: calc(50% - 36px);
      left: calc(50% - 36px);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  .header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    height: 90px;
    border-bottom: 1px solid #fff;
  }
  
  @media (max-width: 575px) {
    .header {
      height: 70px;
    }
  }
  
  .header.sticked {
    border-color: #fff;
    border-color: #eee;
  }
  
  .header .logo img {
    max-height: 40px;
    margin-right: 6px;
  }
  
  .header .logo h1 {
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin: 0;
    font-family: var(--font-secondary);
  }
  
  .header .logo h1 span {
    color: var(--color-primary);
  }
  
  .header .btn-book-a-table,
  .header .btn-book-a-table:focus {
    font-size: 14px;
    color: #fff;
    background: var(--color-primary);
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .header .btn-book-a-table:hover,
  .header .btn-book-a-table:focus:hover {
    color: #fff;
    background: #2996b6;
  }
  
  section {
    scroll-margin-top: 90px;
  }
  
  /*--------------------------------------------------------------
  # Desktop Navigation
  --------------------------------------------------------------*/
  @media (min-width: 1280px) {
    .navbar {
      padding: 0;
    }
  
    .navbar ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navbar li {
      position: relative;
    }
  
    .navbar>ul>li {
      white-space: nowrap;
      padding: 10px 0 10px 28px;
    }
  
    .navbar a,
    .navbar a:focus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 3px;
      font-family: var(--font-secondary);
      font-size: 16px;
      font-weight: 600;
      color: #7f7f90;
      white-space: nowrap;
      transition: 0.3s;
      position: relative;
    }
  
    .navbar a i,
    .navbar a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
  
    .navbar>ul>li>a:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -6px;
      left: 0;
      background-color: var(--color-primary);
      visibility: hidden;
      width: 0px;
      transition: all 0.3s ease-in-out 0s;
    }
  
    .navbar a:hover:before,
    .navbar li:hover>a:before,
    .navbar .active:before {
      visibility: visible;
      width: 100%;
    }
  
    .navbar a:hover,
    .navbar .active,
    .navbar .active:focus,
    .navbar li:hover>a {
      color: #000;
    }
  
    .navbar .dropdown ul {
      display: block;
      position: absolute;
      left: 28px;
      top: calc(100% + 30px);
      margin: 0;
      padding: 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      border-radius: 4px;
    }
  
    .navbar .dropdown ul li {
      min-width: 200px;
    }
  
    .navbar .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      font-weight: 600;
    }
  
    .navbar .dropdown ul a i {
      font-size: 12px;
    }
  
    .navbar .dropdown ul a:hover,
    .navbar .dropdown ul .active:hover,
    .navbar .dropdown ul li:hover>a {
      color: var(--color-primary);
    }
  
    .navbar .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navbar .dropdown .dropdown ul {
      top: 0;
      left: calc(100% - 30px);
      visibility: hidden;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: 100%;
      visibility: visible;
    }
  }
  
  @media (min-width: 1280px) and (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  @media (min-width: 1280px) {
  
    .mobile-nav-show,
    .mobile-nav-hide {
      display: none;
    }
  }
  
  /*--------------------------------------------------------------
  # Mobile Navigation
  --------------------------------------------------------------*/
  @media (max-width: 1279px) {
    .navbar {
      position: fixed;
      top: 0;
      right: -100%;
      width: 100%;
      max-width: 400px;
      border-left: 1px solid #666;
      bottom: 0;
      transition: 0.3s;
      z-index: 9997;
    }
  
    .navbar ul {
      position: absolute;
      inset: 0;
      padding: 50px 0 10px 0;
      margin: 0;
      background: rgba(255, 255, 255, 0.9);
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
    }
  
    .navbar a,
    .navbar a:focus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      font-family: var(--font-secondary);
      border-bottom: 2px solid rgba(255, 255, 255, 0.8);
      font-size: 16px;
      font-weight: 600;
      color: #7f7f90;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navbar a i,
    .navbar a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
  
    .navbar a:hover,
    .navbar li:hover>a {
      color: #000;
    }
  
    .navbar .active,
    .navbar .active:focus {
      color: #000;
      border-color: var(--color-primary);
    }
  
    .navbar .dropdown ul,
    .navbar .dropdown .dropdown ul {
      position: static;
      display: none;
      padding: 10px 0;
      margin: 10px 20px;
      transition: all 0.5s ease-in-out;
      border: 1px solid #eee;
    }
  
    .navbar .dropdown>.dropdown-active,
    .navbar .dropdown .dropdown>.dropdown-active {
      display: block;
    }
  
    .mobile-nav-show {
      color: var(--color-secondary);
      font-size: 28px;
      cursor: pointer;
      line-height: 0;
      transition: 0.5s;
      z-index: 9999;
      margin: 0 10px 0 20px;
    }
  
    .mobile-nav-hide {
      color: var(--color-secondary);
      font-size: 32px;
      cursor: pointer;
      line-height: 0;
      transition: 0.5s;
      position: fixed;
      right: 20px;
      top: 20px;
      z-index: 9999;
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .navbar {
      right: 0;
    }
  
    .mobile-nav-active .navbar:before {
      content: "";
      position: fixed;
      inset: 0;
      background: rgba(255, 255, 255, 0.8);
      z-index: 9996;
    }
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about .about-img {
    min-height: 500px;
  }
  
  .about h3 {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 30px;
    font-family: var(--font-secondary);
  }
  
  .about .call-us {
    left: 10%;
    right: 10%;
    bottom: 10%;
    background-color: #fff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
    padding: 20px;
    text-align: center;
  }
  
  .about .call-us h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
    font-family: var(--font-default);
  }
  
  .about .call-us p {
    font-size: 28px;
    font-weight: 700;
    color: var(--color-primary);
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding: 0 0 8px 26px;
    position: relative;
  }
  
  .about .content ul i {
    position: absolute;
    font-size: 20px;
    left: 0;
    top: -3px;
    color: var(--color-primary);
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(var(--color-primary) 50%, rgb(206 185 18 / 40%) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .about .play-btn:before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgb(255 255 255 / 70%);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .about .play-btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .about .play-btn:hover:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  .about .play-btn:hover:after {
    border-left: 15px solid var(--color-primary);
    transform: scale(20);
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Why Us Section
  --------------------------------------------------------------*/
  .why-us .why-box {
    padding: 30px;
    background: var(--color-primary);
    color: #fff;
  }
  
  .why-us .why-box h3 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 30px;
  }
  
  .why-us .why-box p {
    margin-bottom: 30px;
  }
  
  .why-us .why-box .more-btn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.3);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
  }
  
  .why-us .why-box .more-btn i {
    font-size: 14px;
  }
  
  .why-us .why-box .more-btn:hover {
    color: var(--color-primary);
    background: #fff;
  }
  
  .why-us .icon-box {
    text-align: center;
    background: #fff;
    padding: 40px 30px;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(55, 55, 63, 0.1);
    transition: 0.3s;
  }
  
  .why-us .icon-box i {
    color: var(--color-primary);
    margin-bottom: 30px;
    font-size: 32px;
    margin-bottom: 30px;
    background: rgba(206, 18, 18, 0.1);
    border-radius: 0 50% 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background: #e1f1f270;
    
  }
  .why-us .icon-box i:hover {
    background: var(--color-primary);
    color: white;
  }
  
  .why-us .icon-box h4 {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 30px 0;
    font-family: var(--font-secondary);
  }
  
  .why-us .icon-box p {
    font-size: 15px;
    color: #6c757d;
  }
  
  @media (min-width: 1200px) {
    .why-us .icon-box:hover {
      transform: scale(1.1);
    }
  }
  
  /*--------------------------------------------------------------
  # Stats Counter Section
  --------------------------------------------------------------*/
  .stats-counter {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./img/stats-bg1.jpg") center center;
    background-size: cover;
    padding: 100px 0;
  }
  
  @media (min-width: 1365px) {
    .stats-counter {
      background-attachment: fixed;
    }
  }
  
  .stats-counter .stats-item {
    padding: 30px;
    width: 100%;
  }
  
  .stats-counter .stats-item span {
    font-size: 48px;
    display: block;
    color: #fff;
    font-weight: 700;
  }
  
  .stats-counter .stats-item p {
    padding: 0;
    margin: 0;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.6);
  }
  
  /*--------------------------------------------------------------
  # Menu Section
  --------------------------------------------------------------*/
  .menu .nav-tabs {
    border: 0;
  }
  
  .menu .nav-link {
    margin: 0 10px;
    padding: 10px 5px;
    transition: 0.3s;
    color: var(--color-secondary);
    border-radius: 0;
    cursor: pointer;
    height: 100%;
    border: 0;
    border-bottom: 2px solid #BDE9F5;
  }
  
  @media (max-width: 575px) {
    .menu .nav-link {
      margin: 0 10px;
      padding: 10px 0;
    }
  }
  
  .menu .nav-link i {
    padding-right: 15px;
    font-size: 48px;
  }
  
  .menu .nav-link h4 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    font-family: var(--font-secondary);
  }
  
  @media (max-width: 575px) {
    .menu .nav-link h4 {
      font-size: 16px;
    }
  }
  
  .menu .nav-link:hover {
    color: var(--color-primary);
  }
  
  .menu .nav-link.active {
    color: var(--color-primary);
    border-color: var(--color-primary);
  }
  
  .menu .tab-content .tab-header {
    padding: 30px 0;
  }
  
  .menu .tab-content .tab-header p {
    font-size: 14px;
    text-transform: uppercase;
    color: #676775;
    margin-bottom: 0;
  }
  
  .menu .tab-content .tab-header h3 {
    font-size: 36px;
    font-weight: 600;
    color: var(--color-primary);
  }
  
  .menu .tab-content .menu-item {
    -moz-text-align-last: center;
    text-align-last: center;
  }
  
  .menu .tab-content .menu-item .menu-img {
    padding: 0 60px;
    margin-bottom: 15px;
  }
  
  .menu .tab-content .menu-item h4 {
    font-size: 22px;
    font-weight: 500;
    color: var(--color-secondary);
    font-family: var(--font-secondary);
    font-weight: 30px;
    margin-bottom: 5px;
  }
  
  .menu .tab-content .menu-item .ingredients {
    font-family: var(--font-secondary);
    color: #8d8d9b;
    margin-bottom: 5px;
  }
  
  .menu .tab-content .menu-item .price {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-primary);
  }
  

  /*--------------------------------------------------------------
  # Chefs Section
  --------------------------------------------------------------*/
  .chefs .chef-member {
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0 30px rgba(55, 55, 63, 0.08);
    transition: 0.3s;
  }
  
  .chefs .chef-member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .chefs .chef-member .member-img:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    /* background: url(./img/team-shape.svg) no-repeat center bottom; */
    background-size: contain;
    z-index: 1;
  }
  
  .chefs .chef-member .social {
    position: absolute;
    right: -100%;
    top: 30px;
    opacity: 0;
    border-radius: 4px;
    transition: 0.5s;
    background: rgba(255, 255, 255, 0.3);
    z-index: 2;
  }
  
  .chefs .chef-member .social a {
    transition: color 0.3s;
    color: rgba(55, 55, 63, 0.4);
    margin: 15px 12px;
    display: block;
    line-height: 0;
    text-align: center;
  }
  
  .chefs .chef-member .social a:hover {
    color: rgba(55, 55, 63, 0.9);
  }
  
  .chefs .chef-member .social i {
    font-size: 18px;
  }
  
  .chefs .chef-member .member-info {
    padding: 10px 15px 20px 15px;
  }
  
  .chefs .chef-member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: var(--color-secondary);
  }
  
  .chefs .chef-member .member-info span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: rgba(33, 37, 41, 0.4);
  }
  
  .chefs .chef-member .member-info p {
    font-style: italic;
    font-size: 14px;
    padding-top: 15px;
    line-height: 26px;
    color: rgba(33, 37, 41, 0.7);
  }
  
  .chefs .chef-member:hover {
    transform: scale(1.08);
    box-shadow: 0px 0 30px rgba(55, 55, 63, 0.15);
  }
  
  .chefs .chef-member:hover .social {
    right: 8px;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Kyçu Section
  --------------------------------------------------------------*/
  .book-a-table .reservation-img {
    min-height: 500px;
    background-size: cover;
    background-position: center;
  }
  
  .book-a-table .reservation-form-bg {
    background: rgba(55, 55, 63, 0.04);
  }
  
  .book-a-table .contact-form {
    padding: 40px;
  }
  
  @media (max-width: 575px) {
    .book-a-table .contact-form {
      padding: 20px;
    }
  }
  
  .book-a-table .contact-form h3 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .book-a-table .contact-form h4 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 20px 0 0 0;
  }
  
  .book-a-table .contact-form p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .book-a-table .contact-form .error-message {
    display: none;
    color: #fff;
    background: #df1529;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .book-a-table .contact-form .sent-message {
    display: none;
    color: #fff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .book-a-table .contact-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .book-a-table .contact-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #059652;
    border-top-color: #fff;
    animation: animate-loading 1s linear infinite;
  }
  
  .book-a-table .contact-form input,
  .book-a-table .contact-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 0;
  }
  
  .book-a-table .contact-form input:focus,
  .book-a-table .contact-form textarea:focus {
    border-color: var(--color-primary);
  }
  
  .book-a-table .contact-form input {
    padding: 12px 15px;
  }
  
  .book-a-table .contact-form textarea {
    padding: 12px 15px;
  }
  
  .book-a-table .contact-form button[type=submit] {
    background: var(--color-primary);
    border: 0;
    padding: 14px 60px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .book-a-table .contact-form button[type=submit]:hover {
    background: #2996b6;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
 
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    width: 100%;
    background-size: cover;
    position: relative;
    min-height: 60vh;
    padding: 160px 0 60px 0;
  }
  
  .hero h2 {
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--color-secondary);
    font-family: var(--font-primary);
  }
  
  .hero h2 span {
    color: var(--color-primary);
  }
  
  .hero p {
    color: #4f4f5a;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .hero img {
    border-radius: 16%;
    border: 8px solid white;
  }
  
  .hero .btn-book-a-table {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 36px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: var(--color-primary);
    box-shadow: 0 8px 28px rgba(206, 18, 18, 0.2);
  }
  
  .hero .btn-book-a-table:hover {
    background: #2996b6;
    box-shadow: 0 8px 28px rgba(206, 18, 18, 0.45);
  }
  
  .hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: var(--font-secondary);
    font-weight: 600;
  }
  
  .hero .btn-watch-video i {
    color: var(--color-primary);
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
  }
  
  .hero .btn-watch-video:hover {
    color: var(--color-primary);
  }
  
  .hero .btn-watch-video:hover i {
    background: #2996b6;
  }
  
  @media (max-width: 640px) {
    .hero h2 {
      font-size: 36px;
    }
  
    .hero .btn-get-started,
    .hero .btn-watch-video {
      font-size: 14px;
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  .footer {
    font-size: 14px;
    background-color: #1f1f24;
    padding: 50px 0;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .footer .icon {
    margin-right: 15px;
    font-size: 24px;
    line-height: 0;
  }
  
  .footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 5px;
    color: #fff;
  }
  
  .footer .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-links ul a:hover {
    color: #fff;
  }
  
  .footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .social-links a:hover {
    color: #fff;
    border-color: #fff;
  }
  
  .footer .copyright {
    text-align: center;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .footer .credits {
    padding-top: 4px;
    text-align: center;
    font-size: 13px;
  }
  
  .footer .credits a {
    color: #fff;
  }
  



.cardHotels {
  display: grid;
  place-items: center;
  width: 80vw;
  max-width: 21.875rem;
  height: 28.125rem;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.cardHotels > * {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.card__background {
  object-fit: cover;
  max-width: 100%;
  height: 456px;

}

.card__content {
  height: 100%;
  --flow-space: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-end;
  /* height: 55%; */
  padding: 12% 1.25rem 1.875rem;
  background: linear-gradient( 180deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0.3) 10%, hsl(0, 0%, 0%) 100% );
  overflow: auto;
}

.card__content--container {
  --flow-space: 1.25rem;
}

.card__title {
  position: relative;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
}

.card__title::after {
  content: "";
  position: absolute;
  height: 0.3125rem;
  width: calc(100% + 1.25rem);
  bottom: calc((1.25rem - 0.5rem) * -1);
  left: -1.25rem;
  background-color: var(--brand-color);
}

.card__button {
  padding: 0.75em 1.6em;
  width: fit-content;
  width: -moz-fit-content; /* Prefijo necesario para Firefox  */
  font-variant: small-caps;
  font-weight: bold;
  border-radius: 0.45em;
  border: none;
  background-color: var(--brand-color);
  font-family: var(--font-title);
  font-size: 1.125rem;
  color: var(--black);
}

.card__button:focus {
  outline: 2px solid black;
  outline-offset: -5px;
}

@media (any-hover: hover) and (any-pointer: fine) {
  .card__content {
    transform: translateY(62%);
    transition: transform 500ms ease-out;
    transition-delay: 500ms;
  }

  .card__title::after {
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 1000ms ease-in, transform 500ms ease-out;
    transition-delay: 500ms;
    transform-origin: right;
  }

  .card__background {
    transition: transform 500ms ease-in;
  }

  .card__content--container > :not(.card__title),
  .card__button {
    opacity: 0;
    transition: transform 500ms ease-out, opacity 500ms ease-out;
  }

  .cardHotels:hover,
  .cardHotels:focus-within {
    transform: scale(1.05);
    transition: transform 500ms ease-in;
  }

  .cardHotels:hover .card__content,
  .cardHotels:focus-within .card__content {
    transform: translateY(0);
    transition: transform 500ms ease-in;
  }

  .cardHotels:focus-within .card__content {
    transition-duration: 0ms;
  }

  .cardHotels:hover .card__background,
  .cardHotels:focus-within .card__background {
    transform: scale(1.3);
  }

  .cardHotels:hover .card__content--container > :not(.card__title),
  .cardHotels:hover .card__button,
  .cardHotels:focus-within .card__content--container > :not(.card__title),
  .cardHotels:focus-within .card__button {
    opacity: 1;
    transition: opacity 500ms ease-in;
    transition-delay: 1000ms;
  }

  .cardHotels:hover .card__title::after,
  .cardHotels:focus-within .card__title::after {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transition-delay: 500ms;
  }
}

a {
  text-decoration: none;
}

.pricingTable {
  text-align: center;
  background: #fff;
  margin: 0 -15px;
  box-shadow: 0 0 10px #ababab;
  padding-bottom: 40px;
  border-radius: 10px;
  color: #cad0de;
  transform: scale(1);
  transition: all .5s ease 0s
}

.pricingTable:hover {
  transform: scale(1.05);
  z-index: 1
}

.pricingTable .pricingTable-header {
  padding: 17px 0;
  background: #f5f6f9;
  border-radius: 10px 10px 50% 50%;
  transition: all .5s ease 0s
}

.pricingTable:hover .pricingTable-header {
  background: #6ECDE9
}

.pricingTable .pricingTable-header i {
  font-size: 50px;
  color: #858c9a;
  margin-bottom: 10px;
  transition: all .5s ease 0s
}

.pricingTable .price-value {
  font-size: 35px;
  color: #6ECDE9;
  transition: all .5s ease 0s
}

.pricingTable .month {
  display: block;
  font-size: 14px;
  color: #cad0de
}

.pricingTable:hover .month,
.pricingTable:hover .price-value,
.pricingTable:hover .pricingTable-header i {
  color: #fff
}

.pricingTable .heading {
  font-size: 24px;
  color: #6ECDE9;
  margin-bottom: 20px;
  text-transform: uppercase
}

.pricingTable .pricing-content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 30px
}

.pricingTable .pricing-content ul li {
  line-height: 30px;
  color: #a7a8aa;
  text-align: start;
  margin: 8px 26px;
}

.pricingTable .pricingTable-signup a {
  display: inline-block;
  font-size: 15px;
  color: #fff;
  padding: 10px 35px;
  border-radius: 20px;
  background: #6ECDE9;
  text-transform: uppercase;
  transition: all .3s ease 0s
}

.pricingTable .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #6ECDE9
}

.pricingTable.blue .heading,
.pricingTable.blue .price-value {
  color: #5A8693
}

.pricingTable.blue .pricingTable-signup a,
.pricingTable.blue:hover .pricingTable-header {
  background: #5A8693
}

.pricingTable.blue .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #5A8693
}

.pricingTable.red .heading,
.pricingTable.red .price-value {
  color: #ff4b4b
}

.pricingTable.red .pricingTable-signup a,
.pricingTable.red:hover .pricingTable-header {
  background: #ff4b4b
}

.pricingTable.red .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #ff4b4b
}

.pricingTable.green .heading,
.pricingTable.green .price-value {
  color: #b7d02a
}

.pricingTable.green .pricingTable-signup a,
.pricingTable.green:hover .pricingTable-header {
  background: #b7d02a
}

.pricingTable.green .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #b7d02a
}

.pricingTable.blue:hover .price-value,
.pricingTable.green:hover .price-value,
.pricingTable.red:hover .price-value {
  color: #fff
}

@media screen and (max-width:990px) {
  .pricingTable {
      margin: 0 0 20px
  }
}

