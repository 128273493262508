// $primary: #428af5;
// $secondary: #f1f1f1;
// $primary-shade: #8db8f9;

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 999;

  .validation-msg-modal {
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #218838;
  }
}

.mymodal {
  z-index: 999;
  // position: relative;
  position: absolute;
  top: 5%;
  background: #fff;
  overflow: auto;
  // border: 1px solid rgb(197, 194, 194);
  border-radius: 5px;
  width: 50%;
  // height: 60%;
  height: 100%;
  max-height: 600px;
  overflow: auto;
  //margin-right: auto;
  //margin-left: auto;
  outline: none;
  display: flex;
  justify-content: center;

  .audio-player {
    margin-bottom: 12px;
    margin-top: 26px;
    // margin-left: 10%;
    audio {
      width: 100%;
    }
  }

  .validation-msg-modal {
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #218838;
  }
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.modal-header {
  // background-color: #dedfe0;
  width: 100%;
  // margin-top: -43px ;
  // text-align: center;
  // justify-content: center;
  margin-bottom: 30px;
  // background-color: $primary;
  // color: #fff;
  // box-shadow: inset 0 -3em 3em rgba(66, 138, 245, 0.753),
  //       0 0  0 2px rgb(255,255,255),
  //       0.3em 0.3em 1em rgba(13, 94, 170, 0.3);
  color: #495057;
  // color: #fff;

  p {
    font-size: 22px;
    // margin: auto;
    font-weight: bold;
    padding: 0 0 0 75px;
    margin: auto 0;
  }
  button {
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: #fff;
  }
}

.content-part {
  .close-btn {
  }
  // .paginationBttns {
  //   width: 80%;
  //   height: 40px;
  //   list-style: none;
  //   display: flex;
  //   justify-content: center;
  // }

  // .paginationBttns {
  //   a {
  //     padding: 10px;
  //     margin: 8px;
  //     border-radius: 5px;
  //     // border: 1px solid $primary;
  //     // color: $primary;
  //     cursor: pointer;
  //   }
  // }

  // .paginationBttns {
  //   a {
  //     &:hover {
  //       color: white;
  //       // background-color: $primary;
  //     }
  //   }
  // }

  // .paginationActive {
  //   a {
  //     color: white;
  //     // background-color: $primary;
  //   }
  // }

  // .paginationDisabled {
  //   a {
  //     display: none;
  //   }
  // }

  h1 {
    display: flex;
    justify-content: center;
  }

  .confirm-button {
    //justify-content: center;
    //margin: auto;
  }

  //border: 3px solid red;
  justify-content: center;
  align-items: center;
  display: flex;
  //width:50%;
  button {
    //border: 3px solid red;
    //width: 100%;
    width: 100px;
    margin-right: 5px;
    margin-left: 5px;
  }
}

.audio-part {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  //align-content: center;
  align-items: center;

  .delete-modal {
    text-align: center;
  }

  .formAdd {
    width: 90%;
    margin: auto;
    .input-group-text {
      background-color: #428af5 !important;
      color: #fff;
    }
    .form-modal {
      label {
        font-weight: bold;
        font-size: 12.5px;
      }
    }
  }

  .title-part {
    //border: 3px solid red;
    display: flex;
    align-items: center;
    align-content: center;
    .validation-msg-modal {
      margin-top: 1rem;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #218838;
    }
  }
  .button-left {
    text-align: end;

    .submit-button {
      width: 35%;
      // background-color: $primary;
      color: #fff;
      border: none;
      font-weight: 500;
      padding: 7px;
    }
  }

  .validation-msg-modal {
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #218838;
  }
}

.points {
  margin-left: 17%;
  .edit-point-button {
    border: none;
    // background: $primary;
    color: white;
    margin-bottom: 30px;
  }

  .image-part {
    margin-bottom: 5px;
    width: 50%;
    height: 76px;
  }

  .validation-msg-modal {
    font-weight: bold;
    font-size: 14px;
    color: #218838;
  }

  .buttons-group {
    display: flex;
    justify-content: center;

    .img-part {
      width: 40px;
      height: 40px;
    }

    // .showme:hover {
    //   .hidemee {
    //     display: inline;
    //   }
    // }
    // .hidemee {
    //   transition: 0.3s ease-in-out;
    //   display: none;
    // }

    .btn-plus {
      border: none;
      // width: 10%;
      width: 40px;
      height: 40px;
      //margin-left: 20px;
      // background: $primary;
      border-radius: 100%;
      padding: 10px;
      // color: $secondary;
      font-size: 14px;
      transition: 0.8s ease-in-out;
      margin-top: 7px;
    }
  }

  .map-button {
    background: unset;
    padding: 12px;
    //transition: .3s ease-in-out;
    font-size: 28px;
    border: unset;
    margin-bottom: 5px;
    transition: 0.3s ease-in-out;

    .map-button-txt {
      margin-left: 20px;
      // background: $primary-shade;
      border-radius: 10px;
      padding: 10px;
      // color: $secondary;
      font-size: 18px;
      transition: 0.8s ease-in-out;
    }

    .fas {
      // color: $primary;
    }

    &:hover,
    &:focus {
      i {
        // color: $primary-shade;
      }
    }
  }

  .myButton {
    // background-color: $primary;
    // color: $secondary;
    border-radius: 5px;
    border: unset;
    padding: 10px;
    margin-bottom: 5px;
  }

  .listimi {
    width: 100%;
    // background-color: $primary;
    // color: $secondary;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;

    a {
      // color: $secondary;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.formAdd {
  .text-error {
    color: maroon;
    font-weight: 600;
    font-size: 20px;
  }

  .text-validation {
    color: #218838;
    font-weight: 600;
    font-size: 20px;
  }

  .leaflet-container {
    // height: 400px;
    height: 30vw;
    width: 70vw;
    position: relative;
    border: 2px solid white;
    //border: 3px solid red;
    @media screen and (max-width: 767px) {
      width: 100vw;

    }
  }

  .image-form {
    margin-top: -2.5%;
    padding: 1.2%;
    background: white;
    border-radius: 0 0 20px 20px;

    button {
      float: right;

      &:disabled {
        background: #fff;
      }
    }
  }

  width: 50%;

  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  .btn-plus {
    border: 3px solid red;
  }

  .map-save-button {
    position: absolute;
    z-index: 99999;
    top: 5%;
    right: 5%;
    padding: unset;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    // background: $primary;
    // color: $secondary;
    border: unset;
    font-size: 30px;
    transition: 0.3s ease-in-out;

    &:hover {
      font-size: 35px;
    }
  }

  button {
    width: 50%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
}

.mapHeight {
  height: 535px !important;
}

/*---------------------------------------------------------------------- /
SECTIONS
----------------------------------------------------------------------- */

.section-cards {
  z-index: 3;
  position: relative;
}

.section-gray {
  background: #d4dbdf;
  padding: 30px 0 30px 0;
  border-radius: 12px;
  border-bottom: 3px solid #BDE9F5;
  border-top: 3px solid #BDE9F5;
}

/*---------------------------------------------------------------------- /
CARDS
----------------------------------------------------------------------- */

.card {
  display: inline-block;
  position: relative;
  width: 100%;
  // margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}



.card .card-image img {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  pointer-events: none;
}

.card .card-image .card-caption {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
  font-size: 1.3em;
  text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}

.card img {
  width: 100%;
  height: auto;
}

.img-raised {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .ftr {
  margin-top: 15px;
}

// .card .ftr div {
//   display: inline-block;
// }

.card .ftr .author {
  color: #888;
}

.card .ftr .stats {
  float: right;
  line-height: 30px;
}

.card .ftr .stats {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.card-blog {
  margin-top: 30px;
}

.card-blog .card-caption {
  margin-top: 5px;
}

.card-blog .card-image + .category {
  margin-top: 20px;
}

.card-raised {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

/* ============ Card Background ============ */

.card-background {
  background-position: center;
  background-size: cover;
  text-align: center;
}

.card-background .table {
  position: relative;
  z-index: 2;
  min-height: 280px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 440px;
  margin: 0 auto;
}

.card-background .category,
.card-background .card-description,
.card-background small {
  color: rgba(255, 255, 255, 0.8);
}

.card-background .card-caption {
  color: #ffffff;
  margin-top: 10px;
}

.card-background:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  table: "";
  background-color: rgba(0, 0, 0, 0.56);
  border-radius: 6px;
}

.Neon {
  font-family: sans-serif;
  font-size: 14px;
  color: #494949;
  position: relative;
}
.Neon * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Neon-input-dragDrop {
  display: block;
  width: 343px;
  margin: 0 auto 25px auto;
  padding: 25px;
  color: #8d9499;
  color: #97a1a8;
  background: #fff;
  border: 2px dashed #c8cbce;
  text-align: center;
  -webkit-transition: box-shadow 0.3s, border-color 0.3s;
  -moz-transition: box-shadow 0.3s, border-color 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.Neon-input-dragDrop .Neon-input-icon {
  font-size: 48px;
  margin-top: -10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.Neon-input-text h3 {
  margin: 0;
  font-size: 18px;
}
.Neon-input-text span {
  font-size: 12px;
}
.Neon-input-choose-btn.blue {
  color: #008bff;
  border: 1px solid #008bff;
}
.Neon-input-choose-btn {
  display: inline-block;
  padding: 8px 14px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  color: #8d9496;
  border-radius: 3px;
  border: 1px solid #c6c6c6;
  vertical-align: middle;
  background-color: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}
.inputImage {
  z-index: 999;
  opacity: 0;
  width: 320px;
  height: 200px;
  position: absolute;
  right: 0px;
  left: 0px;
  margin-right: auto;
  margin-left: auto;
}


                
#upload {
  opacity: 0;
}

#upload-label {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

.image-area {
  border: 2px dashed rgba(255, 255, 255, 0.7);
  padding: 1rem;
  position: relative;
}

.image-area::before {
  content: 'Uploaded image result';
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  z-index: 1;
}

.image-area img {
  z-index: 2;
  position: relative;
}
.imageButtons{
  position: absolute;
  top: 0px;
  color: black;
  background: rgb(255 255 255 / 83%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 27px;

}