      
      $contW: 900px;
      $imgW: 260px;
      $formW: $contW - $imgW;
      $switchAT: 1.2s;
      
      $inputW: 260px;
      $btnH: 36px;
      
      $diffRatio: ($contW - $imgW) / $contW;
      
      @mixin signUpActive {
        .cont.s--signup & {
          @content;
        }
      }

      .sub-cont {
        overflow: hidden;
        position: absolute;
        left: $formW;
        top: 0;
        width: $contW;
        height: 100%;
        padding-left: $imgW;
        background: #fff;
        transition: transform $switchAT ease-in-out;
      
        @media (max-width: 600px) {

          // overflow: hidden;
          // position: absolute;
            left: 637px;
          // top: 0;
          // width: 900px;
          // height: 100%;
           padding-left: unset; 
    padding-right: unset;
          // background: #fff;
          // transition: transform 1.2s ease-in-out;
      
          }
        @include signUpActive {
          transform: translate3d($formW * -1,0,0);
          
        @media (max-width: 600px) {

          transform: translate3d(-637px, 0, 0);
      
        }
        }
        
// @media (max-width: 600px) {
//   @include signUpActive {
//     // transform: translate3d($formW * -1,0,0);
//     display: none;
//   }
  
//   }
   
      }
      .sign-in {
        transition-timing-function: ease-out;
      
        @include signUpActive {
          transition-timing-function: ease-in-out;
          transition-duration: $switchAT;
          transform: translate3d($formW,0,0);
        }
      }
      
      .sign-up {
        transform: translate3d($contW * -1,0,0);
      
        @include signUpActive {
          transform: translate3d(0,0,0);
        }
      }

      .imgSignUp {
        overflow: hidden;
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        width: $imgW;
        height: 100%;
        padding-top: 360px;
      
        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: $contW;
          height: 100%;
          background-image: url('https://www.traveloffpath.com/wp-content/uploads/2022/07/Historical-City-Of-Prizren-Kosovo-Photographed-At-Dusk-Balkans-Southeastern-Europe.jpg');
          background-size: cover;
          transition: transform $switchAT ease-in-out;
        }
      
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.6);
        }
      
        @include signUpActive {
          &:before {
            transform: translate3d($formW,0,0);
          }
        }
      
        &__text {
          z-index: 2;
          position: absolute;
          left: 0;
          top: 50px;
          width: 100%;
          padding: 0 20px;
          text-align: center;
          color: #fff;
          transition: transform $switchAT ease-in-out;
      
          h2 {
            margin-bottom: 10px;
            font-weight: normal;
          }
      
          p {
            font-size: 14px;
            line-height: 1.5;
          }
      
          &.m--up {
      
            @include signUpActive {
              transform: translateX($imgW*2);
            }
          }
      
          &.m--in {
            transform: translateX($imgW * -2);
      
            @include signUpActive {
              transform: translateX(0);
            }
          }
        }
      
        &__btn {
          overflow: hidden;
          z-index: 2;
          position: relative;
          width: 100px;
          height: $btnH;
          margin: 0 auto;
          background: transparent;
          color: #fff;
          text-transform: uppercase;
          font-size: 15px;
          cursor: pointer;
          
          &:after {
            content: '';
            z-index: 2;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 2px solid #fff;
            border-radius: 30px;
          }
      
          span {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            transition: transform $switchAT;
            
            &.m--in {
              transform: translateY($btnH*-2);
              
              @include signUpActive {
                transform: translateY(0);
              }
            }
            
            &.m--up {
              @include signUpActive {
                transform: translateY($btnH*2);
              }
            }
          }
        }
      }
.signUpForm{
  padding: 6%;
    input, button {
        border: none;
        outline: none;
        background: none;
        font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      }

      
      .tip {
        font-size: 20px;
        margin: 10% auto;
        text-align: center;
      }
      
      .cont {
        overflow: hidden;
        position: relative;
        width: $contW;
        height: 613px;
        margin:  auto;
        background: #fff;
        
        // .sub-cont{
        //   display: none;
        // }
        
@media (max-width: 600px) {
  width: unset;
  height: 787px;
  margin:  auto;
  .sub-cont{
    .sub-cont {

       width: unset; 
       padding-left: 0px;
    }
    .imgSignUp{

    display: none;
  }
  }
  }
      }
      
      form {
        position: relative;
        width: $formW;
        height: 100%;
        transition: transform $switchAT ease-in-out;
        padding: 0px 30px 0;
        display: flex;
        align-content: flex-start;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: 600px) {
          width: 100%;
          padding: 24px 0px 0;
          /* margin: 0 auto; */
          }
      }
      
    
      
      button {
        display: block;
        margin: 0 auto;
        width: $inputW;
        height: $btnH;
        border-radius: 30px;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
      }
      
     
      
      h2 {
        width: 100%;
        font-size: 26px;
        text-align: center;
        font-size: 49px;
        font-weight: 700;
        margin-bottom: 20px;
        /* color: var(--color-secondary); */
        font-family: var(--font-primary);
      }
      
      label {
        display: block;
        width: $inputW;
        margin: 25px auto 0;
        text-align: center;
      
        span {
          font-size: 12px;
          color: #cfcfcf;
          text-transform: uppercase;
        }
      }
      
      input {
        display: block;
        width: 100%;
        margin-top: 5px;
        padding-bottom: 5px;
        font-size: 16px;
        border-bottom: 1px solid rgba(0,0,0,0.4);
        text-align: center;
      }
      
      .forgot-pass {
        margin-top: 15px;
        text-align: center;
        font-size: 12px;
        color: #cfcfcf;
      }
      
      .submit {
        margin-top: 20px;
        margin-bottom: 20px;
        background: #6ECDE9;
        text-transform: uppercase;
        &:hover{
          background: #2996b6;
        }
      }
      
      .fb-btn {
        border: 2px solid #d3dae9;
        color: darken(#d3dae9, 20%);
      
        span {
          font-weight: bold;
          color: darken(#768cb6, 20%);
        }
      }
      
   
      
      .icon-link {
        position: absolute;
        left: 5px;
        bottom: 5px;
        width: 32px;
      
        img {
          width: 100%;
          vertical-align: top;
        }
      
        &--twitter {
          left: auto;
          right: 5px;
        }
      }
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-container input {
  padding-right: 30px; /* Adjust based on icon size */
}

.password-container i {
  position: absolute;
  right: 10px;
}



@media (max-width: 600px) {
.signUpForm{

}
 
}