$default-color: white;
$secondary-color: rgb(255, 87, 88);

@keyframes toggler_close_animation {
  from {
    left: 216px;
  }

  to {
    left: 90px;
  }
}

@keyframes toggler_open_animation {
  0% {
    left: 90px;
  }

  100% {
    left: 216px;
  }
}

.sidebar_layout {
  position: fixed;
  height: 100%;
  z-index: 99;

    .menu-item {
    // Default styles for the menu item
    color: black;
    background-color: $default-color;
    border-radius: 12px;

    &.active1 {
      // Active styles for the menu item
      background-color: $secondary-color;
      color: white;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    position: unset;
  }

  .termini-logo-text {
    width: 80%;
  }

  .iconCollapsed {
    width: 100%;
  }

  .sidebar-custom {
    overflow: auto;
    position: absolute;
    height: 100vh;
    background-color: #6ecde9;
    border: 1px solid lightgray;

    @media only screen and (max-width: 600px) {
      z-index: -1;
      position: relative;
    }
    .ps-sidebar-root {
      width: 100% !important;
      border: none;
    }
  }

  .collapse_sidebar_button {
    position: absolute;
    z-index: 9;
    top: 16px;

    i {
      color: #797a7c;
      font-size: 35px;
    }

    &.collapsed {
      left: 100px;

      @media only screen and (max-width: 600px) {
        right: 20px;
        left: unset;
            position: fixed;
      }
    }

    &:not(.collapsed) {
      left: 216px;

      @media only screen and (max-width: 600px) {
        right: 20px;
        left: unset;
      }
    }
  }

  .logo_layout {
    text-align: center;
    .logo_first_letter {
      width: 30px;
      height: 30px;
      color: black;
      font-weight: bold;
      font-size: xx-large;
      padding-right: 30px;
    }

    .logo_other_letters {
      height: 30px;
      display: flex;
      align-items: self-end;
      margin: 0 0 0 0px;
      font-weight: bolder;
      font-size: x-large;
    }

    .logo_last_letters {
      margin: 0;
      height: 28px;
      display: flex;
      align-items: self-end;
      font-weight: bolder;
      font-size: 20px;
      color: gray;
    }
  }

  .logo-collapsed {
    width: 100px;
    height: 87px;
    margin-top: 9px;
    margin-right: 5px;
    padding-top: 3px;
    padding-bottom: 0px;
    border-bottom: 2px solid lightgray;
  }

  .menu-icons-collapsed {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .menu-icons {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .submenu-icons {
    width: 30px;
    height: 30px;
  }

  .sidebar_footer_layout {
    z-index: 9;
    width: auto;
    height: 30px;
    position: fixed;
    bottom: 0;
    left: 0.5%;

    &.collapsed {
      width: 36px;
      height: 50px;
      margin: 15px;
      margin: auto;
      background-color: white;
      position: relative;
      bottom: 0px;
    }
  }

 .logout-button{
  color: black;
  padding: 10px;
  position: absolute;
  bottom: 0%;
  width: 100%;
  background-color: #6ecde9;
  cursor: pointer;
  &:hover{
    background-color: white;
  }
 }
  .harrisia-icon {
    width: 60px;
    height: auto;
  }

  .widthi {
    z-index: 999 !important;
    width: 70% !important;
  }

  .borderheader {
    border-bottom: 1px solid lightgray;
    padding: 0;
    margin: 0;
        height: 60px;
  }

  @media only screen and (max-width: 600px) {
    .sidebar-custom {
      position: unset !important;
      visibility: visible !important;
      width: 100%;

      &.collapsed {
        display: none;
      }
    }
  }
  
}

.active1 {
  // Active styles for the menu item
  background-color:white;
  color: white;
}
.notCollapsedSideBar{
padding: 20px;
  @media only screen and (max-width: 900px) {
    margin-left: 250px;
    width: calc(100% - 250px);
  }
  @media only screen and (max-width: 600px) {
   display: none;
  }
}
.collapsedSideBar:not(.addButtonNotCollapsed){
  position: relative;
  // width: 90%;
  // margin: 0 auto;
  z-index: 1;
  // @media only screen and (min-width: 1100px) {
  //   width: 89%;
  //   // margin: 3% 9%;
  //  }
  @media only screen and (max-width: 1100px) {
    width: 86%;
    margin: 2% 12%;
   }
  @media only screen and (max-width: 800px) {
    margin:14% auto;
    margin-bottom: unset;
   }
  @media only screen and (max-width: 600px) {
    // display: none;
    margin-top: 24%;
   }
}
.notCollapsedSideBar {
  padding: 20px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.collapsedSideBar:not(.addButtonNotCollapsed) {
  position: relative;
  // width: 90%;
  // margin: 0 auto;
  z-index: 1;


  // @media only screen and (max-width: 850px) {
  //   width: 95%;
  //   margin-left: unset;
  // }
}

@media only screen and (min-width: 600px) {
  .collapsedSideBar{

    margin-left: 118px;
    width: calc(100% - 130px);
    margin-top: 20px;
  }
}
// .notCollapsedSideBar {
//   padding: 20px;

//   @media only screen and (max-width: 600px) {
//     display: none;
//   }
// }

// .collapsedSideBar:not(.addButtonNotCollapsed) {
//   position: relative;
//   width: 90%;
//   margin: 0 auto;
//   z-index: 1;

//   @media only screen and (min-width: 600px) {
//     margin-left: 250px;
//     width: calc(100% - 250px);
//   }

//   @media only screen and (max-width: 850px) {
//     width: 95%;
//     margin-left: unset;
//   }
// }