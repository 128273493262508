.eventImage {
    position: absolute;
    top: 5%;
    left: 30%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  @media screen and (max-width: 767px) {
    .eventImage {
      width: 80px;
      height: 80px;
      top: 3%;
    }
  }
  
  @media screen and (max-width: 479px) {
    .eventImage {
      width: 60px;
      height: 60px;
      left: 23%;
      top: 1%;
    }
  }